import CookieManager from '@kvass/cookie-manager'

import store from './store'

let CM = {}

function loadCM(i18n) {
  const pageId = store.state.Root.item.id

  let consents = [
    {
      id: 'required',
      required: true,
      title: i18n.$t('consentRequiredTitle'),
      description: i18n.$t('consentRequiredDescription'),
    },
    {
      id: 'statistics',
      title: i18n.$t('consentStatisticsTitle'),
      description: i18n.$t('consentStatisticsDescription'),
    },
    {
      id: 'marketing',
      title: i18n.$t('consentMarketingTitle'),
      description: i18n.$t('consentMarketingDescription'),
    },
  ]

  return (CM = new CookieManager({
    title: i18n.$t('consentDialogTitle'),
    description: i18n.$t('pageConsentDialogDescription', {
      link: `<a target="_blank" href="/api/legal/privacy/page/${pageId}">${i18n
        .$t('privacyPolicyTitle')
        .toLowerCase()}</a>`,
    }),
    labels: {
      acceptAll: i18n.$t('consentDialogLabelAcceptAll'),
      confirm: i18n.$t('consentDialogLabelConfirm'),
      decline: i18n.$t('consentDialogLabelDecline'),
    },
    consents,
    onChange(res) {
      if (Kvass) Kvass.emit('consent:change', res)
    },
  }))
}

if (Kvass) {
  Kvass.on('consent:show', CM.show)
  Kvass.on('consent:get', cb => cb(CM.getConsents()))
}

export default loadCM
